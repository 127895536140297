import React, { useEffect } from 'react'
import { logout } from '../services/auth'

export default (props) => {
  useEffect(() => {
    logout()
  }, [])

  return <div />
}
